.body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #ed3338;
  display: table;
  font-weight: 100;
  font-family: 'Lato';
}

.container {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.content {
  text-align: center;
  display: inline-block;
}

.title {
  font-size: 96px;
  margin-bottom: 40px;
}

.quote {
  font-size: 24px;
}

.h1{
  font-size: 140px;
  margin: 0;
  font-weight: 300;
}

.a{
  font-weight: 600;
  color: #fff;
  background: #ed3338;
  padding:7px 15px;
  display: inline-block;
  text-decoration: none;
}